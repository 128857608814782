<template>
  <nav class="nav containers-fluid">
    <div>
      <img src="@/assets/images/Rectangle.webp" class="rectangle" alt="CodeHard" :height=100 :width=100 />
    </div>
    <div class="nav-main">
      <div class="containers px-0">
        <button class="mr-1 hamburger">
          <MenuCh />
          <span @click="showMenu" ref="menu" class="box-0"></span>
        </button>
        <div class="nav-content">
          <div class="logo">
            <router-link to="/" aria-label="Read more about CodeHard">
              <span v-bind:style="`background-image:url(` + logoImg + `)`"></span>
            </router-link>
            <LottieCh />
          </div>
          <div class="menu" :class="{ 'open': isOpen }">
            <ul class="nav-items">
              <li><router-link to="/">Home</router-link></li>
              <li class="dropdown-menu">
                <DropdownCH menu-title="Product" :isActive="isActiveProduct">
                  <ul class="nav-items d-col">
                    <li><router-link to="/ch-product/document-editor">Online Documents</router-link></li>
                    <li><router-link to="/ch-product/ticketing">Ticketings</router-link></li>
                    <li><router-link to="/ch-product/photo-stock">Photo Stock</router-link></li>
                    <li><router-link to="/ch-product/procurement">Procurement</router-link></li>
                    <li><router-link to="/ch-product/speech-to-text">Speech To Text</router-link></li>

                  </ul>
                </DropdownCH>
              </li>
              <li class="dropdown-menu">
                <DropdownCH menu-title="Service" :isActive="isActiveService">
                  <ul class="nav-items d-col">
                    <li><router-link to="/ch-service/application">Application Development</router-link></li>
                    <li><router-link to="/ch-service/network">Infrastructure Architecture</router-link></li>
                    <li><router-link to="/ch-service/mobile">Mobile Application</router-link></li>
                    <li><router-link to="/ch-service/microservices">Microservices Design</router-link></li>
                    <li><router-link to="/ch-service/secure">Vulnerability Scan</router-link></li>
                    <li><router-link to="/ch-service/training">Training Program</router-link></li>
                    <li><router-link to="/ch-service/ch-outsourcing-service">Outsourcing Service</router-link></li>
                  </ul>
                </DropdownCH>
              </li>
              <li class="dropdown-menu-mobile">
                <ul>
                  Product
                  <li><router-link to="/ch-product/document-editor">Online Documents</router-link></li>
                  <li><router-link to="/ch-product/ticketing">Ticketings</router-link></li>
                  <li><router-link to="/ch-product/photo-stock">Photo Stock</router-link></li>
                  <li><router-link to="/ch-product/procurement">Procurement</router-link></li>
                  <li><router-link to="/ch-product/speech-to-text">Speech To Text</router-link></li>
                </ul>
              </li>
              <li class="dropdown-menu-mobile">
                <ul>
                  Service
                  <li><router-link to="/ch-service/application">Application Development</router-link></li>
                  <li><router-link to="/ch-service/network">Infrastructure Architecture</router-link></li>
                  <li><router-link to="/ch-service/mobile">Mobile Application</router-link></li>
                  <li><router-link to="/ch-service/microservices">Microservices Design</router-link></li>
                  <li><router-link to="/ch-service/secure">Vulnerability Scan</router-link></li>
                  <li><router-link to="/ch-service/training">Training Program</router-link></li>
                  <li><router-link to="/ch-service/ch-outsourcing-service">Outsourcing Service</router-link></li>
                </ul>
              </li>
              <li><router-link to="/ch-career">Career</router-link></li>
              <!-- <li><router-link to="/ch-blog">Blog</router-link></li> -->
              <li><router-link to="/ch-about">About Us</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import DropdownCH from '@/components/shared/DropdownCH';
import MenuCh from '@/components/shared/MenuCh';
import logoImg from '@/assets/logo.webp';

export default {
  components: {
    DropdownCH,
    MenuCh
  },
  data() {
    return {
      isOpen: false,
      isActiveProduct: false,
      isActiveService: false,
      logoImg: logoImg
    }
  },
  methods: {
    showMenu() {
      var _this = this
      const closeListerner = (e) => {
        if (_this.catchOutsideClick(e, _this.$refs.menu))
          window.removeEventListener('click', closeListerner), _this.isOpen = false

      }
      window.addEventListener('click', closeListerner)
      this.isOpen = !this.isOpen
    },
    catchOutsideClick(event, dropdown) {
      if (dropdown == event.target)
        return false
      if (this.isOpen && dropdown != event.target)
        return true
    },
    setIsActive() {
      this.isActiveProduct = false;
      this.isActiveService = false;

      if (this.$route.meta.groupMeta === "product") {
        this.isActiveProduct = true
      }
      if (this.$route.meta.groupMeta === "service") {
        this.isActiveService = true
      }
    }
  },
  watch: {
    $route() {
      this.setIsActive();
    }
  },
};

</script>

<style lang="scss">
.nav {
  .hamburger {
    cursor: pointer;
    background: transparent;
    padding: 0;
    position: relative;

    .box-0 {
      position: absolute;
      top: 0;
      left: 0;
      background: transparent;
      width: 100%;
      height: 100%;
    }
  }

  .dropDownMenuWrapper {
    .dropDownMenuButton {
      font-weight: 100;
      font-family: "Hubot-Sans", sans-serif;
    }
  }

  .rectangle {
    position: fixed;
    top: 0px;
    right: 0;
    z-index: 5;
    width: 5%;
    height: auto;
  }

  .containers {
    display: flex;
    align-items: center;
    position: relative;
  }

  &-main {
    background-color: white;
    box-shadow: var(--ch-box-shadow);
    height: 70px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    .hamburger {
      display: none;
    }
  }

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .logo {
      display: flex;
      align-items: center;

      a {
        display: flex;
        height: 70px;
        width: 140px;
      }

      span {
        height: 100%;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
      }
    }

    .nav-items {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;

      .d-col {
        // DropdownCH
        flex-direction: column;
        align-items: flex-start;
        // gap: 10px;

        li {
          border-bottom: 1px solid #96969617;
          width: 100%;

          a {
            height: 35px;
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      li {
        padding: 0 15px;

        button {
          font-size: .8rem;
          height: 70px;
        }

        a {
          color: var(--ch-color-black);
          font-size: .8rem;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-weight: 100;

          &:hover {
            color: rgba(var(--ch-color-black), .5);
          }

          &.router-link-active {
            color: var(--ch-color-primary);
            font-weight: bold;
          }
        }
      }
    }
  }

  .dropdown-menu-mobile {
    display: none;
  }

  .dropdown-menu {
    display: block;
  }

}

@media screen and (max-width: 768px) {
  .nav {
    .hamburger {
      display: block;
    }

    &-content {
      .menu {
        display: none;

        &.open {
          position: absolute;
          width: 100%;
          top: 49px;
          left: 0;
          padding: 1rem 0;
          animation: menu 0.3s ease forwards;
          background-color: #f7f5f5;
          border: 1px solid #dfdede;
          // box-shadow: var(--ch-box-shadow);
          display: block;
          height: 80dvh;
          overflow: scroll;
        }
      }

      .nav-items {
        flex-direction: column;
        align-items: flex-start;

        gap: 20px;

        li {
          button {
            height: unset;
          }

          a {
            height: unset;
            justify-content: flex-start;
          }
        }
      }
    }

    .dropdown-menu {
      display: none;
    }

    .dropdown-menu-mobile {
      display: block;

      ul {
        color: #cfcece;
        font-size: .7rem;

        li {
          margin: .6rem;
        }
      }
    }
  }
}

@keyframes menu {
  from {
    transform: translate3d(0, 30px, 0)
  }

  to {
    transform: translate3d(0, 20px, 0)
  }
}
</style>