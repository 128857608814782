<template>
  <div class="LoadingCh containers-fluid" v-if="state.isShow">
    <LottieAnimation ref="anim" :animation-data="WatermelonJSON" :loop="true" :auto-play="true" />
    <!-- <img src="@/assets/logo.png" alt="CodeHard" /> -->
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { LottieAnimation } from 'lottie-web-vue'
import WatermelonJSON from '@/assets/loading.json'
import { state } from '@/store/loading'

const anim = ref(null);

onMounted(() => {
  setTimeout(() => {
    state.hideLoading();
  }, 2500)
})

watch(() => state.isShow, (newVal) => {
  if (newVal) {
    setTimeout(() => {
      state.hideLoading();
    }, 2500)
  }
})
</script>

<style lang="scss">
.LoadingCh {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 99;
  overflow: hidden;

  div {
    width: 350px;
    margin: auto;
    display: block;

    // ~div {
    //   width: 150px;
    //   margin: auto;
    // }
  }
}
</style>