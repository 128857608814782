<template>
  <section class="dropDownMenuWrapper" :class="{ 'dropDownMenuWrapper--noTitle': !menuTitle }">
    <button class="dropDownMenuButton" :class="{ 'bg': isOpen, 'active': isActive }" ref="menu" @click="openClose">
      {{ menuTitle }}
      <ArrowCH />
    </button>
    <section class="dropdownMenu" v-if="isOpen">
      <slot></slot>
    </section>

  </section>
</template>

<script>
import ArrowCH from '@/assets/images/product/menu/ArrowCH.vue';
export default {
  props: [
    "menuTitle",
    "isActive"
  ],
  components: {
    ArrowCH
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    openClose() {
      var _this = this
      const closeListerner = (e) => {
        if (_this.catchOutsideClick(e, _this.$refs.menu))
          window.removeEventListener('click', closeListerner), _this.isOpen = false

      }
      window.addEventListener('click', closeListerner)
      this.isOpen = !this.isOpen
    },
    catchOutsideClick(event, dropdown) {
      if (dropdown == event.target)
        return false

      if (this.isOpen && dropdown != event.target)
        return true
    },
  },
}

</script>

<style lang="scss" scoped>
.dropDownMenuWrapper {
  position: relative;
  display: flex;
  align-items: center;

  .dropDownMenuButton {
    border: none;
    background: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    color: var(--ch-color-black);
    z-index: 10;
    display: flex;
    align-items: center;

    svg {
      stroke: var(--ch-color-black);
      margin-left: 8px;
    }

    &:hover {
      color: var(--ch-color-black);

      svg {
        stroke: var(--ch-color-black);
      }
    }

    &.active {
      color: var(--ch-color-primary);
      font-weight: bold;

      svg {
        stroke: var(--ch-color-primary);
      }
    }

    &.bg {
      color: var(--ch-color-black);

      svg {
        stroke: var(--ch-color-black);
      }
    }
  }

  .arrow {
    width: 25px;
    padding: 0;
    padding-left: 5px;
  }

  .dropdownMenu {
    position: absolute;
    top: 100%;
    left: -50%;
    border-radius: 8px;
    border: 1px solid #eee;
    background: white;
    padding: 10px;
    animation: menu 0.3s ease forwards;
    display: inline-block;
    white-space: nowrap;
    z-index: 12;
    box-shadow: var(--ch-box-shadow);

    // .menuArrow {
    //   width: 15px;
    //   height: 15px;
    //   position: absolute;
    //   top: -9px;
    //   left: 50%;
    //   border-left: 1px solid #eee;
    //   border-top: 1px solid #eee;
    //   background: white;
    //   transform: rotate(45deg);
    //   border-radius: 4px 0 0 0;
    // }

  }



  @keyframes menu {
    from {
      transform: translate3d(0, 30px, 0)
    }

    to {
      transform: translate3d(0, 20px, 0)
    }
  }

}
</style>