<template>
  <div class="MenuCh" @click="callback($event)">
    <LottieAnimation ref="anim" :animation-data="WatermelonJSON" :loop="true" :auto-play="true" :speed=".5" />
  </div>
</template>

<script>
import { LottieAnimation } from "lottie-web-vue"
import WatermelonJSON from "@/assets/hamburger-menu.json"

export default {
  components: {
    LottieAnimation,
    // Buttons,
  },
  data() {
    return {
      WatermelonJSON,
    }
  },
  methods: {
    callback: function (e) {
      this.$emit('click', e);
    }
  }
}
</script>

<style lang="scss">
.MenuCh {
  width: 38px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>