import { createApp } from 'vue';
import App from './App.vue';
import '@/scss/style.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Vue3Marquee from 'vue3-marquee'

import router from './router';



console.log("%cCode(Hard);", "text-shadow: 1px 1px 2px black, 0 0 1em white, 0 0 0.2em red; font-size: 80px;");
console.log("%c ฮั่นแหน่~ เข้ามาทำอะไรหราาาา? ", "text-shadow: 1px 1px 2px black, 0 0 1em white, 0 0 0.2em red; font-size: 33px;")

const app = createApp(App);
app.use(router)
app.use(Vue3Marquee)
app.mount('#app')