<template>
  <LoadingCh />
  <NavbarCH />
  <main class="main">
    <router-view />
  </main>
  <FooterCH />
</template>

<script>
import NavbarCH from '@/layouts/NavbarCH.vue'
import FooterCH from '@/layouts/FooterCH.vue'
import LoadingCh from '@/components/shared/LoadingCh.vue'

export default {
  name: 'CodeHare',
  components: {
    NavbarCH,
    FooterCH,
    LoadingCh
  },
}
</script>

<style scoped>
.main {
  margin-top: 80px;
}
</style>