import { createWebHistory, createRouter } from "vue-router";
import { state } from "@/store/loading.js";

const routes = [
  {
    path: '/',
    name: "Homepage",
    component: () => import('@/views/HomePage.vue')
  },
  {
    path: '/ch-product/',
    name: "ProductPage",
    redirect: { name: 'ProductDocumentEditor' },
    // component: () => import('@/views/ProductPage.vue'),
    children: [
      {
        path: 'document-editor',
        name: "ProductDocumentEditor",
        component: () => import('@/views/ProductPage.vue'),
        meta: {
          groupMeta: 'product'
        }
      },
      {
        path: 'ticketing',
        name: "ProductPageTicketing",
        component: () => import('@/views/product/TicketingCH.vue'),
        meta: {
          groupMeta: 'product'
        }
      },
      {
        path: 'photo-stock',
        name: "ProductPagePhotoStock",
        component: () => import('@/views/product/PhotoStockCH.vue'),
        meta: {
          groupMeta: 'product'
        }
      },
      {
        path: 'procurement',
        name: "ProductProcurement",
        component: () => import('@/views/product/ProcurementCH.vue'),
        meta: {
          groupMeta: 'product'
        }
      },
      {
        path: 'speech-to-text',
        name: "ProductSpeechToText",
        component: () => import('@/views/product/SpeechToTextCH.vue'),
        meta: {
          groupMeta: 'product'
        }
      },
      // {
      //   path: 'ch-outsourcing-service',
      //   name: "OutsourcingService",
      //   component: () => import('@/views/product/OutsourcingService.vue'),
      //   meta: {
      //     groupMeta: 'service'
      //   }
      // },
    ],
  },

  {
    path: '/ch-service/',
    name: "ServiceAndSolution",
    redirect: { name: 'ServiceApplication' },
    component: () => import('@/views/ServiceAndSolution.vue'),
    children: [
      {
        path: 'application',
        component: () => import('@/views/ServiceAndSolution.vue'),
        name: 'ServiceApplication',
        meta: {
          groupMeta: 'service'
        }
      },
      {
        path: 'network',
        component: () => import('@/views/ServiceAndSolution.vue'),
        name: 'ServiceNetwork',
        meta: {
          groupMeta: 'service'
        }
      },
      {
        path: 'mobile',
        component: () => import('@/views/ServiceAndSolution.vue'),
        name: 'ServiceMobile',
        meta: {
          groupMeta: 'service'
        }
      },
      {
        path: 'microservices',
        component: () => import('@/views/ServiceAndSolution.vue'),
        name: 'ServiceMicroservices',
        meta: {
          groupMeta: 'service'
        }
      },
      {
        path: 'secure',
        component: () => import('@/views/ServiceAndSolution.vue'),
        name: 'ServiceSecure',
        meta: {
          groupMeta: 'service'
        }
      },
      {
        path: 'training',
        component: () => import('@/views/ServiceAndSolution.vue'),
        name: 'ServiceTraining',
        meta: {
          groupMeta: 'service'
        }
      },
      {
        path: 'ch-outsourcing-service',
        component: () => import('@/views/ServiceAndSolution.vue'),
        name: 'OutSourcing',
        meta: {
          groupMeta: 'service'
        }
      },
    ],
  },
  {
    path: '/ch-about',
    name: "AboutUs",
    component: () => import('@/views/AboutCH.vue')
  },
  {
    path: '/ch-career',
    name: "Career",
    component: () => import('@/views/CareerCH.vue')
  },
  {
    path: '/ch-blog',
    name: "BlogCH",
    component: () => import('@/views/BlogCH.vue')
  },
  {
    path: '/ch-online-documents',
    name: "OnlineDocuments",
    component: () => import('@/views/OnlineDocuments.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'router-link-active',
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
});

router.beforeEach((_, __, next) => {
  state.showLoading();

  next();
});

export default router;